require("./index.scss");
import event from "../../modules/szfront_utils/event";
import $on from "../../modules/szfront_utils/on";
import SearchForm from "../../modules/szfront_core/SearchForm";
import langHandler from "../../modules/szfront_core/langHandler";

new SearchForm({
  btnSelector: ".btn_search",
  btnActive: "btn_search_active",

  formSelector: ".header__search",
  formActive: "header__search_active",

  inputSelector: "#top_search",
  innerSelector: ".header__search-in",
  labelSelector: ".header__search-label"
});

let sidebarOpenBtn = document.querySelector(".header__menu-btn");
$on(sidebarOpenBtn, "click", function() {
  event.trigger("layout:sidebar_open");
});

langHandler('.btn.btn_lang');
