import event from "../szfront_utils/event";
import getCookie from "../szfront_utils/getCookie";
import setCookie from "../szfront_utils/setCookie";
import ajaxPost from "../szfront_utils/ajaxPost";
import uuidv4 from "uuid/v4";

export default class CookiePolicy {
  constructor(options) {
    this.banner = document.getElementById(options.banner);
    if (!this.banner) return;

    this.button = document.getElementById(options.button);
    this.visibleDisplay = options.visibleDisplay || "block";

    this.waitingSend = +getCookie("waitingSend");
    this.userIdentifier = getCookie("userIdentifier");
    this.acceptedVersion = +getCookie("cookiePolicy");
    this.currentVersion = +this.banner.dataset.version;
    this.analyticsLabel = this.banner.dataset.type;
    this.newVersion = !this.acceptedVersion || this.acceptedVersion !== this.currentVersion;
    this.termsId = options.termsId;
    this.googleIdsString = "";

    if (!this.userIdentifier && !this.waitingSend) {
      this.acceptance(uuidv4());
    } else if (!this.waitingSend && this.newVersion) {
      this.acceptance(this.userIdentifier);
    } else if (this.waitingSend) {
      this.waitingGa(this.userIdentifier || uuidv4());
    }
  }

  get googleIdentifiers() {
    let _ga, _gid;

    ga.getAll().forEach(tracker => {
      const gaId = tracker.get("clientId");
      const gidId = tracker.get("_gid");

      document.cookie.split(";").forEach(function(el) {
        if (el.includes(gaId)) _ga = el.split("=")[1];
        if (el.includes(gidId)) _gid = el.split("=")[1];
      });
    });

    return [_ga, _gid];
  }

  acceptance = uuid => {
    this.banner.style.display = this.visibleDisplay;
    event.trigger("cookies-policy-show", { label: this.analyticsLabel || "Show" });

    this.button.addEventListener("click", () => {
      this.banner.style.display = "none";
      event.trigger("cookies-policy-accept", { label: this.analyticsLabel || "Click" });

      if (window.ga && ga.loaded) {
        const [_ga, _gid] = this.googleIdentifiers;
        this.googleIdsString = `&ga=${_ga}&gid=${_gid}`;
        this.request(uuid);
      } else {
        setCookie("waitingSend", 1);
        this.waitingGa(uuid);
      }
    });
  };

  waitingGa = uuid => {
    let i = 0;
    const timer = setInterval(() => {
      i++;

      if (window.ga && ga.loaded) {
        const [_ga, _gid] = this.googleIdentifiers;
        this.googleIdsString = `&ga=${_ga}&gid=${_gid}`;
      }

      if ((window.ga && ga.loaded) || i >= 10) {
        clearInterval(timer);
        setCookie("waitingSend", 0);
        this.request(uuid);
      }
    }, 500);
  };

  request = uuid => {
    let requestString = `user_id=${uuid}&terms_id=${this.termsId}&version=${this.currentVersion}${
      this.googleIdsString
    }`;
    this.send(requestString, uuid);
  };

  send = (requestString, uuid) => {
    ajaxPost("https://terms-base.com/api/v1/confirms", requestString)
      .then(() => {
        setCookie("userIdentifier", uuid, 180);
        setCookie("cookiePolicy", this.currentVersion, 180);
      })
      .catch(error => {
        throw new Error(error);
      });
  };
}
