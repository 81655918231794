/**
 * @file application.js
 * @desc Entry point of Application
 */

import initializer from "../modules/szfront_core/initializer";
import Analytics from "../modules/szfront_core/Analytics";
import CookiePolicy from "../modules/szfront_core/cookiePolicy";
import PerformanceMonitor from '../modules/szfront_core/PerformanceMonitor';
import interstitialObserver from "../modules/szfront_core/interstitialObserver";
import doubleObserver from "../modules/szfront_core/doubleObserver";
import ThumbsAnalytics from "../modules/szfront_core/ThumbsAnalytics";
import bannerObserver from "../modules/szfront_core/bannerObserver";
import "../layout/index";

/**
 * Run entire project
 * @type {{start: seezis.start}}
 */
let seezis = {
  start: function() {
    initializer.init();
  }
};

seezis.start();

window.thumbsAnalyticsInstance = new ThumbsAnalytics(
  '/stats/events',
  '.bnr--sidebar, .playlist__bnr, .main-video__banner, .section__ad',
  '.preland__item, .playlist__video, .pl-tile__video',
  '.szp',
);

let analytics = new Analytics({
  category: "Video",
  events: [
    "tags",
    "relatedId",
    "trackOldBrowsers",
    "trackSideBarLinks",
    "trackSideBarOpen",
    "trackSearchBtn",
    "pwa",
    "score",
    "scrollPlaylist",
    "playerAds",
    "pagesViewed2",
    "cookiesPolicy",
    "interstitialAd",
    "prelandClicked",
    "clickAds",
    "userReturned",
    "adsInpage",
    "doubleAd"
  ],
  eventsPage: {
    page_main_index: {
      category: "Index",
      addEvents: ["trackSlider", "trackChannelLinks"]
    }
  },
  tagsEl: ".tag-label",
  playlistEl: ".playlist",
  relatedEl: ".playlist__video",
  channelEl: ".section__btn-link, .section__head a",
  sidebarEl: ".sidebar__item",
  sidebarBtn: ".header__menu-btn",
  sliderEl: ".m-slider__item",
  searchBtn: ".btn.btn_search"
});

analytics.init();

interstitialObserver();
doubleObserver();
bannerObserver('.bnr-analytics');

new CookiePolicy({
  banner: "cookie-banner",
  button: "cookie-btn",
  termsId: "Kivitu Cookie Policy"
});

window.addEventListener('load', () => {
  if (!window.send_performance_data_to || !window.performance.getEntriesByName) return;

  let monitor = new PerformanceMonitor({
    measures: {
      measureRequestStart: ['requestStart'],
      measureResponseEnd: ['responseEnd'],
      measureDomLoading: ['domLoading'],
      measureDomInteractive: ['domInteractive'],
      measureDomContentLoadedEnd: ['domContentLoadedEventEnd'],
      measureDomComplete: ['domComplete'],

      measureLoadPlayer: ['loadPlayerStart', 'loadPlayerEnd'],
    }
  });

  monitor.sendReport(window.send_performance_data_to);
});

export { seezis };
