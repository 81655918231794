/**
 * Wrapper for element.addEventListener()
 * @example
 * $on(el, "click", function() {
 *     console.log("Click on el!");
 * });
 * @param target - Target Element
 * @param type
 * @param callback
 * @param capture
 */

export default function $on(target, type, callback, capture) {
  let events = type.split(' ');
  events.forEach(eventName => {
    target.addEventListener(eventName, callback, !!capture);
  });
}
