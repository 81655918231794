import event from "../szfront_utils/event";
import getCookie from "../szfront_utils/getCookie";
import delayedEvents from "./delayedEvents";

function afterShowedDoubleAds() {
  event.trigger("double-ad-show");
  if (getCookie('shownInterstitial'))
    event.trigger("double-ad-show-cl");
  else
    delayedEvents().add({name: "double-ad-show-cl"});
}

export default function doubleObserver() {
  window.addEventListener('hashchange',({ target }) =>{
    if (target.location.hash === '#goog_game_inter') afterShowedDoubleAds();
  });

  document.addEventListener('double-show', afterShowedDoubleAds);
}
