import $on from "../szfront_utils/on";
import getClosest from "../szfront_utils/getClosest";

export default class SearchForm {
  constructor(options) {
    this.searchBtn = document.querySelector(options.btnSelector);
    this.searchBtnActiveClass = options.btnActive;
    this.searchForm = document.querySelector(options.formSelector);
    this.searchFormActiveClass = options.formActive;
    this.activeSelector = document.querySelector(options.activeSelector) || this.searchForm;

    this.searchFormInput = this.searchForm.querySelector(options.inputSelector);
    this.searchFormInner = document.querySelector(options.innerSelector); // only for animation purposes
    this.searchFormLabel = document.querySelector(options.labelSelector);

    $on(this.searchBtn, "click", () => this.toggleSearch());

    $on(document, "click", event => {
      if (
        !getClosest(event.target, `${options.formSelector}, ${options.btnSelector}`) &&
        this.searchBtn.classList.contains(this.searchBtnActiveClass)
      )
        this.hideForm();
    });

    $on(this.searchFormLabel, "click", () => {
      return this.searchForm.reportValidity() ? this.searchForm.submit() : null;
    });
  }

  toggleSearch() {
    if (this.searchBtn.classList.contains(this.searchBtnActiveClass)) {
      this.hideForm();
    } else {
      this.showForm();
    }
  }

  showForm() {
    this.activeSelector.classList.toggle(this.searchFormActiveClass);
    this.searchBtn.classList.toggle(this.searchBtnActiveClass);
    this.searchForm.style.zIndex = "none";
    let searchFormInput = this.searchFormInput;
    let searchFormInner = this.searchFormInner;

    $on(
      searchFormInner,
      "transitionend",
      function transition() {
        searchFormInput.focus();
        searchFormInner.removeEventListener("transitionend", transition, false);
      },
      false
    );
  }

  hideForm() {
    this.activeSelector.classList.toggle(this.searchFormActiveClass);
    let searchBtn = this.searchBtn;
    let searchBtnActiveClass = this.searchBtnActiveClass;
    let searchForm = this.searchForm;
    let searchFormInner = this.searchFormInner;

    $on(
      searchFormInner,
      "transitionend",
      function transition() {
        searchBtn.classList.toggle(searchBtnActiveClass);
        searchForm.style.zIndex = 0;
        searchFormInner.removeEventListener("transitionend", transition, false);
      },
      false
    );
  }
}
