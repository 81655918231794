export default class MHelper {
  static get hasTouchScreen() {
    if (typeof window === "undefined") return false;
    let hts = false;
    if ("maxTouchPoints" in navigator) {
      hts = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hts = navigator.msMaxTouchPoints > 0;
    } else {
      let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hts = !!mQ.matches;
      } else if ("orientation" in window) {
        hts = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        let UA = navigator.userAgent;
        hts = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return hts;
  }

  static get isLandscape() {
    return window.matchMedia("(orientation: landscape)").matches;
  }

  static get isPortrait() {
    return window.matchMedia("(orientation: portrait)").matches;
  }

  static get orientation() {
    return window.matchMedia("(orientation: landscape)").matches ? "landscape" : "portrait";
  }

  static get screenWidth() {
    return MHelper.isIOS && MHelper.isLandscape ? screen.height : screen.width;
  }

  static get screenHeight() {
    return MHelper.isIOS && MHelper.isLandscape ? screen.width : screen.height;
  }

  static get maxScreenSide() {
    return Math.max(screen.width, screen.height);
  }

  static get pixelRatio() {
    return window.devicePixelRatio;
  }

  static get browserWidth() {
    return window.innerWidth;
  }

  static get browserHeight() {
    return window.innerHeight;
  }

  static get ifSSR() {
    return typeof window === "undefined";
  }

  static get storageNotSupport() {
    return MHelper.ifSSR || !window.localStorage;
  }
}
