/**
 * Sending ajax request to url
 * Promise based
 * @example
 * App.ajax_post('https://httpbin.org/post',"someString=test")
 .then(JSON.parse)
 .then((data)=>{console.log(data.origin)})
 .catch(function (error) {
          throw new Error(error);
        });

 * @param {String} url
 * @param {String} data
 * @param {String} contentType
 * @return {Promise}
 */

export default function ajaxPost(url, data, contentType = "application/x-www-form-urlencoded") {
  return new Promise(function(resolve, reject) {
    let req = new XMLHttpRequest();
    req.open("POST", url);
    req.setRequestHeader("Content-Type", contentType);
    req.onload = function() {
      if (req.status === 200) {
        resolve(req.response);
      } else {
        reject(new Error(req.statusText));
      }
    };

    req.onerror = function() {
      reject(new Error("Network error"));
    };

    req.send(data);
  });
}
