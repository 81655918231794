import $on from "../../modules/szfront_utils/on";
require("./index.scss");

/**
 * WE have to show correct link to search engines
 * but users should go to /settings?lang=lang to get a cookie
 */

let links = [].slice.call(document.querySelectorAll(".header__lang .btn_lang"));

links.forEach(link => {
  $on(link, "click", e => {
    e.preventDefault();
    window.location = link.dataset.href;
  });
});
