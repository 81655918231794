/**
 * Send data with Google gtag method
 * @param {Object} params
 * @param {String} action
 * @param {String} label
 * @param {Number} value
 * @param {String} cat
 */

const sendGtag = ({ action, label, value, category, nonInteraction, debug = false }) => {
  if (!action) {
    console.error("Missing required parameters");
    return;
  }

  if (window.gtag) {
    let gtag = debug
      ? (a, b, c) => {
          window.gtag(a, b, c);
          console.log("%c%s", "color: yellow;", "gtag event:", b, c);
        }
      : window.gtag;

    gtag("event", action, {
      event_label: label,
      value: value,
      event_category: category,
      non_interaction: nonInteraction
    });
  }
};

export default sendGtag;
