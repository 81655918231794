/*
 * @file initializer.js
 * @module core/initializer
 * @desc Detect and run correct module
 * @return {function} init()
 * */

import event from "../szfront_utils/event";

export default (function() {
  /*
   * Retrieve a module for current page
   * @function getCurrentModule*
   * @returns {string} module name
   * */
  function getCurrentModule() {
    return document.body.getAttribute("data-init");
  }

  return {
    init: function() {
      let moduleName = getCurrentModule();
      event.trigger(moduleName + ":init");
    }
  };
})();
