/**
 * WE have to show correct link to search engines
 * but users should go to /settings?lang=lang to get a cookie
 */

import $on from "../szfront_utils/on";

function langHandler(selector) {
  let links = [].slice.call(document.querySelectorAll(selector));

  links.forEach(link => {
    $on(link, "click", e => {
      e.preventDefault();
      window.location = link.getAttribute("data-href");
    })
  });
}

export default langHandler;
