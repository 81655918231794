import $on from "../szfront_utils/on";
import getClosest from "../szfront_utils/getClosest";

class Sidebar {
  constructor(args) {
    this.html = document.documentElement;
    this.sbar = document.querySelector(args.selector);
    this.close = document.querySelector(args.close);
    this.more = document.querySelector(args.more);
    this.text = document.querySelector(args.text);
    this.saClassName = args.saClassName; // Sidebar active className
    this.baClassName = args.baClassName; // Body active className
    this.moreActiveClassName = args.moreActiveClassName; // Text active className

    $on(this.sbar, "click", e => {
      if (!getClosest(e.target, args.inner)) this.hide();
    });

    $on(this.close, "click", () => {
      this.hide();
    })

    $on(this.more, "click", e => {
      this.moreToggle();
    });
  }

  show() {
    this.sbar.classList.add(this.saClassName);
    this.html.classList.add(this.baClassName);
  }

  hide() {
    this.sbar.classList.remove(this.saClassName);
    this.html.classList.remove(this.baClassName);
  }

  moreToggle() {
    if (this.text.classList.contains(this.moreActiveClassName))
      this.text.classList.remove(this.moreActiveClassName);
    else
      this.text.classList.add(this.moreActiveClassName);
  }
}

export default Sidebar;
