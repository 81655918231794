import ajaxPost from "../szfront_utils/ajaxPost";
import uuidv4 from "uuid/v4";
import event from "../szfront_utils/event";

export default class ThumbsAnalytics {
  constructor(url, bannerSelectors, thumbSelectors, playerSelector = "#jw", interval = 2000) {
    this.url = url;
    this.touch();
    this.data = {};
    this.bannerSelectors = bannerSelectors;
    this.thumbSelectors = thumbSelectors;
    this.playerSelector = playerSelector;
    setInterval(() => {
      if (this.isTimeToSend()) {
        this.send();
      }
    }, interval);

    this.observe();

    event.on("desktop-banner-init", ({ detail }) => {
      this.observeBanners(this.getBanners(detail.selector));
    });

    event.on("interstitial-ad-show", () => {
      this.send({ interstitial: true });
    });

    event.on("adRequest", () => {
      this.vastRequestStart = Date.now();
      this.send({ action: "request" });
    });

    event.on("adImpression", ({ detail }) => {
      this.send({ creativetype: detail.label, action: "impression" });
    });

    event.on("adClick", ({ detail }) => {
      this.send({ creativetype: detail.label, action: "click" });
    });

    event.on("adSkipped", ({ detail }) => {
      this.send({ creativetype: detail.label, action: "skip" });
    });

    event.on("adComplete", ({ detail }) => {
      this.send({ creativetype: detail.label, action: "complete" });
    });

    event.on("thumb-click", ({ detail }) => {
      this.send({ thumbClick: detail.thumbId });
    })

    // event.on("page:beforeVisit", () => {
    //   this.send({ nextVideo: true });
    // });
  }

  get thumbs() {
    const elements = [];

    if (this.thumbSelectors.indexOf(",") > -1)
      this.thumbSelectors.split(",").map(selector => this.getThumbsEl(elements, selector));
    else 
      this.getThumbsEl(elements, this.thumbSelectors);

    return elements;
  }

  getThumbsEl(elements, selector) {
    let els = [].slice.call(document.querySelectorAll(selector));
    if (els) {
      els.forEach(el => {
        elements.push(el);
      })
    }
  }

  observeThumbs(thumbs = this.thumbs) {
    thumbs.forEach(thumb => {
      this.thumbObserver.observe(thumb);
    });
  }

  unobserveThumbs() {
    this.thumbs.forEach(thumb => {
      this.thumbObserver.unobserve(thumb);
    });
  }

  get thumbObserver() {
    return new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const { isIntersecting, target, intersectionRatio } = entry;
          const id = target.dataset.thumbId;

          if (isIntersecting && intersectionRatio >= 0.5) {
            this.thumbStart(id);
          }
          if (!isIntersecting || intersectionRatio < 0.5) {
            this.stop(id);
          }
        });
      },
      {
        threshold: [0, 0.5]
      }
    );
  }

  thumbStart(th) {
    const item  = this.data[th];
    if (!item) this.data[th] = {};
    else if (item.isTracked) return false;
    this.data[th].startTime = Date.now();
    this.data[th].thumb = th;
    this.data[th].isTracked = true;
    this.touch();
  }

  getBanners(selectors = this.bannerSelectors) {
    const banners = [];

    if (selectors.indexOf(",") > -1) selectors.split(",").map(item => this.addBanner(item, banners));
    else this.addBanner(selectors, banners);

    return banners;
  }

  addBanner(item, banners) {
    const bannerItem = this.getBannerEl(item);
    if (bannerItem) banners.push(bannerItem);
  }

  getBannerEl(item) {
    const elAdx = document.querySelector(item + ' > div[id^="div-gpt-ad"]');
    const elAds = document.querySelector(item + " > ins.adsbygoogle");

    if (elAdx) {
      elAdx.dataset.doneChecking = "data-google-query-id";
      return elAdx;
    }

    if (elAds) {
      elAds.dataset.doneChecking = "data-adsbygoogle-status";
      return elAds;
    }
  }

  observeBanners(banners = this.getBanners()) {
    banners.forEach(banner => {
      banner.dataset.impressionId = uuidv4();
      banner.dataset.slotname = banner.parentNode.dataset.slotname;
      banner.dataset.slotkey = banner.parentNode.dataset.slotid;
      this.bannerObserver.observe(banner);
    });
  }

  unobserveBanners() {
    this.getBanners().forEach(banner => {
      this.bannerObserver.unobserve(banner);
    });
  }

  get bannerObserver() {
    return new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const { isIntersecting, target, intersectionRatio } = entry;
          const id = target.dataset.slotname;

          if (isIntersecting && intersectionRatio >= 0.5) {
            this.start(id, null, target);
          }
          if (!isIntersecting || intersectionRatio < 0.5) {
            this.stop(id);
          }
        });
      },
      {
        threshold: [0, 0.5]
      }
    );
  }

  observe = () => {
    this.observeThumbs();
    this.observeBanners();
  }

  unobserve = () => {
    this.unobserveBanners();
    this.unobserveThumbs();
  }

  reobserveThumbs = () => {
    this.unobserveThumbs();
    setTimeout(() => this.observeThumbs(), 0);
  }

  start(id, slug, target) {
    const timer = setInterval(() => {
      if (target.getAttribute(target.dataset.doneChecking)) {
        clearInterval(timer);

        let sl = slug || id;
        let item = this.data[sl];
        if (!item) {
          this.data[sl] = {};
        } else if (item.isTracked) return false;

        this.data[sl].startTime = Date.now();
        this.data[sl].id = id;
        this.data[sl].isTracked = true;
        if (target) this.data[sl].target = target;

        this.touch();
      }
    }, 500);
  }

  stop(id, slug) {
    let sl = slug || id;
    let item = this.data[sl];
    if (!item) return false;

    let time = Date.now();
    item.isTracked = false;
    if (!item.resultTime) {
      item.resultTime = time - item.startTime;
    } else {
      item.resultTime = item.resultTime + time - item.startTime;
    }
    this.touch();
  }

  isTimeToSend() {
    return this.haveFreshData || Date.now() - this.lastSendTime > 10000;
  }

  touch() {
    this.haveFreshData = true;
    this.lastSendTime = Date.now();
  }

  untouch() {
    this.haveFreshData = false;
    this.lastSendTime = Date.now();
  }

  send(options) {
    // let video = [];
    let thumbs = [];
    let adsInpage = [];
    let adsInterstitial = [];
    let adsVast = [];

    if (Object.entries(this.data).length) {
      for (let index in this.data) {
        let item = this.data[index];
        let duration = item.duration ? item.duration : Date.now() - item.startTime;

        if (item.target) {
          adsInpage.push({
            page_path: window.location.pathname,
            screen_width: this.screenWidth,
            screen_height: this.screenHeight,
            browser_width: this.browserWidth,
            browser_height: this.browserHeight,
            slot_name: item.id,
            slot_id: item.target.dataset.slotkey,
            slot_width: item.target.offsetWidth,
            slot_height: item.target.offsetHeight,
            impression_id: item.target.dataset.impressionId,
            duration: duration
          });
        }

        if (item.thumb) {
          thumbs.push({
            action: "show video-thumb",
            id: item.thumb,
            time: duration,
          });
        }

        if (item.isTracked) {
          item.startTime = Date.now();
          item.duration = undefined;
        } else {
          delete this.data[index];
        }
      }
    }

    if (options && options.interstitial) {
      adsInterstitial.push({
        page_path: window.location.pathname,
        screen_width: this.screenWidth,
        screen_height: this.screenHeight,
        browser_width: this.browserWidth,
        browser_height: this.browserHeight
      });
    }

    const isAdRequest = options && options.action && options.action === "request";

    if (options && options.action && (options.creativetype || isAdRequest)) {
      adsVast.push({
        page_path: window.location.pathname,
        creativetype: options.creativetype,
        duration: !isAdRequest ? Date.now() - this.vastRequestStart : undefined,
        screen_width: this.screenWidth,
        screen_height: this.screenHeight,
        browser_width: this.browserWidth,
        browser_height: this.browserHeight,
        player_width: this.playerWidth,
        player_height: this.playerHeight,
        action: options.action,
        vast_id: 0 // window.vast_urls[0].id
      });
    }

    if (options && options.thumbClick) {
      thumbs.push({
        action: "click video-thumb",
        id: options.thumbClick,
      });
    }

    this.untouch();

    if (thumbs.length || adsInpage.length || adsInterstitial.length || adsVast.length) {
      ajaxPost(
        this.url,
        JSON.stringify({
          // events: video,
          locationPath: window.location.pathname,
          events: thumbs,
          ads_inpage: adsInpage,
          ads_interstitial: adsInterstitial,
          ads_vast: adsVast
        }),
        "application/json; charset=utf-8"
      ).catch(error => {
        throw new Error(error);
      });
    }
  }

  get isIOS() {
    return navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i);
  }

  get isLandscape() {
    return window.matchMedia("(orientation: landscape)").matches;
  }

  get screenWidth() {
    return this.isIOS && this.isLandscape ? screen.height : screen.width;
  }

  get screenHeight() {
    return this.isIOS && this.isLandscape ? screen.width : screen.height;
  }

  get browserWidth() {
    return window.innerWidth;
  }

  get browserHeight() {
    return window.innerHeight;
  }

  get player() {
    return document.querySelector(this.playerSelector);
  }

  get playerWidth() {
    return this.player.offsetWidth;
  }

  get playerHeight() {
    return this.player.offsetHeight;
  }
}
