import event from "../szfront_utils/event";
import MHelper from "../szfront_utils/MHelper";
import setCookie from "../szfront_utils/setCookie";
import delayedEvents from "./delayedEvents";

const cookieLifetime = 0.013889; // время жизни куки в днях

function afterShowedAds() {
  event.trigger("interstitial-ad-show", { label: MHelper.orientation });
  setCookie("shownInterstitial", 1, cookieLifetime);
  delayedEvents().send();
}

export default function interstitialObserver() {
  window.addEventListener('hashchange',({ target }) =>{
    if (target.location.hash === '#google_vignette') afterShowedAds();
  });

  document.addEventListener('yad-ad-show', afterShowedAds);
}
