import event from "../szfront_utils/event";

const delayedEvents = () => {
  return {
    add: (ev) => {
      if (!window.szDelayedEvents) window.szDelayedEvents = [];
      const isExists = window.szDelayedEvents.some(item => item.name === ev.name);
      if (!isExists) {
        window.szDelayedEvents.push(ev);
      }
    },
    send: () => {
      if (window.szDelayedEvents) {
        window.szDelayedEvents.forEach((ev) => {
          event.trigger(ev.name, ev.detail);
        });
      }
    }
  }
}

export default delayedEvents;
