import event from "../szfront_utils/event";
import getCookie from "../szfront_utils/getCookie";
import delayedEvents from "./delayedEvents";

export default function bannerObserver(selector) {
  let timers = {};

  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        const { isIntersecting, intersectionRatio, target } = entry;
        const slotName = target.dataset.slotname;
        const actionName = target.dataset.actionName;
        const actionNameSpecial = target.dataset.actionNameSpecial;

        if ( !isIntersecting && intersectionRatio < 0.5 ) {
          clearTimeout(timers[slotName]);
          timers[slotName] = null;
        } else if(!timers[slotName] && !+entry.target.dataset.sendedEvent ) {
          timers[slotName] = setTimeout(() => {
            entry.target.dataset.sendedEvent = 1;
            event.trigger("ad-show", { label: slotName });
            if (actionName)
              event.trigger("ad-action", { action: actionName });
            if (actionNameSpecial) {
              if (getCookie('shownInterstitial'))
                event.trigger("ad-action", { action: actionNameSpecial });
              else
                delayedEvents().add({name: "ad-action", detail: { action: actionNameSpecial }})
            }
              
          }, 1000);
        }

        document.addEventListener("page:beforeVisit", () => {
          timers = {};
          entry.target.dataset.sendedEvent = 0;
          observer.disconnect();
        });
      });
    },
    { threshold: [0, 0.5] }
  );

  const els = [].slice.call(document.querySelectorAll(selector));

  els.forEach(item => {
    observer.observe(item);
  })
}
