import "./header";
import "./sidebar";
import "./cookie-banner";
import "./lang-selector";

require("./common/reset.scss");
require("./index.scss");
require("./../libs/kivitu-icons/style.css");
require("./../libs/exo2/exo2.scss");
require("./common/textpage.scss");
